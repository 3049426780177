<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Tpo Registration</v-toolbar-title>
      <v-spacer></v-spacer>
      <template>
        <!-- <div color="primary">
          <v-btn color="primary" @click="dialog_attachment = true"
            ><v-icon>mdi-attachment</v-icon>New Tpo Registration</v-btn
          >
        </div> -->
        <div v-if="registration">
          <v-btn color="primary"  dark @click="open_dialog_attachment()"
            ><v-icon>mdi-plus</v-icon>New Tpo Registration</v-btn
          >
        </div>





        <div v-else>
        </div>
      </template>
    </v-toolbar>
    
     <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader>

    <v-row>
      <v-col cols="12" sm="4" v-for="item in learner_tpo_reg" :key="item">
        <v-card>
          <v-card-title class="c-title">
            {{ item.ptype }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" sm="4">
                <span class="lbl">Academic Year</span>
              </v-col>
              <v-col cols="6" md="6"> : {{ item.ay }} </v-col>
            </v-row>
            <!-- <v-row>    
                            <v-col cols="6" sm="4">
                                <span class="lbl">Semester</span>
                            </v-col>
                            <v-col cols="6" md="6">
                                : {{item.sem}}
                            </v-col>
                        </v-row>  -->
            <v-row>
              <v-col cols="6" sm="4">
                <span class="lbl">Interested</span>
              </v-col>
              <v-col cols="6" md="6">
                <span v-if="item.isactive">
                  :
                  <v-icon class="mr-2 v-icon-size" color="success"
                    >mdi-check-circle-outline</v-icon
                  >
                </span>
                <span v-else>
                  :
                  <v-icon class="mr-2 v-icon-size" color="error"
                    >mdi-close-circle-outline</v-icon
                  >
                </span>
                <!-- : {{item.isactive}} -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-avatar style size="20">
              <!--   <v-icon style size="20" color="error" @click="deleteItem(item)">mdi-delete-empty</v-icon>-->
              <v-icon
                style
                size="20"
                color="error"
                @click="deleteTPORegistration(item)"
                >mdi-delete-empty</v-icon
              >
            </v-avatar>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-app id="inspire" class>
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="learner_tpo_reg" sort-by="ptype" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Tpo Registration</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                            <span style="width: 20px;"></span>  
                           -->
    <v-dialog v-model="dialog" max-width="500px">
      <!-- <template v-slot:activator="{ on }">
                                    <v-btn color="indigo darken-4" dark class="mb-2" v-on="on">New Tpo Registration</v-btn>
                                </template> -->

      <v-card>
        <v-card-title class="c-title">
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
             <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.ay"
                  autocomplete="off"
                  :readonly="editedIndex == -1 ? false : true"
                  item-text="ay"
                  item-value="id"
                  label="Select Academic Year"
                  persistent-hint
                  :filled="editedIndex == -1 ? false : true"
                  :error="errorMsg.ay ? true : false"
                  :error-messages="errorMsg.ay ? 'Required' : ''"
                  return-object
                  single-line
                  :items="list"
                  @change="check()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="editedIndex != -1">
                Academic Year
                <v-select
                  dense
                  v-model="editedItem.ay"
                  autocomplete="off"
                  :items="list"
                  item-value="id"
                  persistent-hint
                  :error="errorMsg.ay ? true : false"
                  :error-messages="errorMsg.ay ? 'Required' : ''"
                  
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="editedItem.ptype"
                  :items="ptype_list"
                  :readonly="editedIndex == -1 ? false : true"
                  item-text="ptype"
                  item-value="id"
                  label="Select Placement Type"
                  persistent-hint
                  :filled="editedIndex == -1 ? false : true"
                  :error="errorMsg.ptype ? true : false"
                  :error-messages="errorMsg.ptype ? 'Required' : ''"
                  return-object
                  single-line
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="editedIndex != -1">
                Placement Type
                <v-select
                  dense
                  v-model="editedItem.ptype"
                  :items="ptype_list"
                  item-value="id"
                  persistent-hint
                  :error="errorMsg.ptype ? true : false"
                  :error-messages="errorMsg.ptype ? 'Required' : ''"
                ></v-select>
              </v-col>

             

              <!-- <v-col cols="12">
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.sem"
                                                        :items="sem_list"
                                                        :readonly="editedIndex==-1?false:true"
                                                        item-text="sem"
                                                        item-value="id"
                                                        label="Select Semester"
                                                        persistent-hint
                                                        :filled="editedIndex==-1?false:true"
                                                        :error="errorMsg.sem?true:false"
                                                        :error-messages="errorMsg.sem?'Required':''"
                                                        return-object
                                                        single-line
                                                    ></v-select>
                                                </v-col> -->
              <!-- <v-col cols="12" v-if="editedIndex != -1">
                                                    Semester
                                                    <v-select dense v-model="editedItem.sem" :items="sem_list" item-value="id" persistent-hint :error="errorMsg.sem?true:false" :error-messages="errorMsg.sem?'Required':''"></v-select>
                                                </v-col> -->
            </v-row>
           <!-- <v-row dense>
              <v-col cols="12">
                <v-checkbox
                  dense
                  v-model="editedItem.isactive"
                  color="success"
                  label="Active?"
                ></v-checkbox>
              </v-col>
              <v-col>
                <input type="hidden" v-model="editedItem.id" name="id" />-->
                <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
            <!--  </v-col>
            </v-row>-->
          </v-container>
          <v-row v-if='!visible && this.editedItem.ay!="" ' justify="center">
          <b class="red--text">Registration close</b>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn v-if='visible' color="primary darken-1"  @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialog_attachment">
      <v-card>
        <v-card-title class="c-title">
          <span class="headline">Policy For Students Placements & Internships</span>
          <span style="margin-left: 630px;"><v-icon size="35" @click="dialog_attachment=false" color="red">mdi-close-circle-outline</v-icon></span>
        </v-card-title>
        <v-card-text>
        <div id="policy_id"></div>

        <v-row cols="12" sm="12">
              <v-col cols="12" sm="12">
                <center>
                <div>
                  <v-btn color="primary" @click="()=> {dialog_attachment=false; dialog=true}">
                    I Agree
                  </v-btn>
                  <v-btn color="primary" class="ml-10" dark @click="dialog_attachment = false">
                    Cancel
                  </v-btn>
                </div>
                </center>
              </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <template v-slot:item.action="{ item }">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
                                        <v-avatar style size="20" >
                                            <v-icon style size="20" color="error" @click="deleteItem(item)">mdi-delete-empty</v-icon>
                                        </v-avatar>
                                    </template>
                                    <span>edit</span>
                                </v-tooltip>
                            </template>  -->
    <!-- </v-toolbar> -->
    <!-- </template>
                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                            <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                     </template>
                </v-data-table>
            </template> 
        </v-app> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    errorMsg: {
      ptype: false,
      //  sem: false,
      ay: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    dialog_attachment: false,
    search: "",
    load: false,
    dialog: false,
    learner_tpo_reg: null,
    ptype_list: null,
    visible:false,
    //            sem_list: null,
    ay_list: null,
    list : null,
     loading:false,
    headers: [
      {
        text: "Placement Type",
        value: "ptype",
      },
      {
        text: "Academic Year",
        value: "ay",
      },
      // {
      //     text: "Semester",
      //     value: "sem",
      // },
      {
        text: "Active",
        value: "isactive",
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: "",
      ptype: "",
      // sem: "",
      ay: "",
      isactive: true,
    },
    defaultItem: {
      id: "",
      ptype: "",
      // sem: "",
      ay: "",
      isactive: true,
    },
    ayreg: [],
    registration:"",
    init_loading:false,
    templatename: "",
        templatebody: "",
        save_loading: "",
        file: null,
        url:""
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Tpo Registration"
        : "Edit Tpo Registration";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.onload();
  },

  methods: {
    open_dialog_attachment()
    {
      this.dialog_attachment=true;
      this.getpolicy();
    },
      check(){
          for(var i in this.ayreg)
          {
          if(this.ayreg[i][this.editedItem.ay])
            {
                this.visible=true;
                
                  return
            }
            else
            {
                    this.visible=false;
                   
                    
            }
          }
         //console.log(this.ayreg[this.editedItem.ay])
      },
    onload() {
       this.loading= true;
      axios
        .post("/TPORegistration/addlearnerTpoReg")
        .then((res) => {
          if (res.data.msg == "200") {
            this.learner_tpo_reg = res.data.learner_tpo_reg;
            this.ptype_list = res.data.ptype_list;
            //this.sem_list = res.data.sem_list;
            this.ay_list = res.data.ay_list.sort();
            this.list = res.data.list;
            this.ayreg = res.data.ayreg;
            this.load = true;
             this.loading= false;
            //console.log(this.ayreg)
            this.registration = res.data.registration;
            //console.log("A:"+res.data.registration)

           
          } else {
          }
        })
        .catch((error) => {
         // window.console.log(error);
        });
    },
    getpolicy() {
            this.init_loading = true;
            axios
                .post("/AdminMaster/registration_policy",)

                .then((res) => {
                    if (res.data.msg == "200") {
                        this.init_loading = false;
                        this.templatebody = res.data.body;
                       if(this.templatebody)
                       {
                        this.dialog_attachment=true;
                        document.getElementById("policy_id").innerHTML=this.templatebody;

                       }
                       else{
                        this.dialog_attachment=false;
                        this.dialog=true;
                       }
                        this.url=res.data.url;

                    }
                    else {
                        this.init_loading = false;
                        this.showSnackbar("red", res.data.msg);
                        this.dialog=true;
                    }
                })
        },
    initializeErrorMsgFalse() {
      this.errorMsg.ptype = false;
      // this.errorMsg.sem = false;
      this.errorMsg.ay = false;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    editItem(item) {
      this.editedIndex = this.learner_tpo_reg.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.initializeErrorMsgFalse();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          //  console.log("edit.." + this.editedItem);

          axios
            .post("/TPORegistration/edit", this.editedItem)
            .then((res) => {
              if (res.data.msg == "200") {
                Object.assign(
                  this.learner_tpo_reg[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar(
                  "#4caf50",
                  "TPO Registration Updated Successfully..."
                ); // show snackbar on success
              }
            })
            .catch((error) => {
              //window.console.log(error);
            });

          this.close();
        }
      } else {
        if (this.isValidated()) {
          axios
            .post("/TPORegistration/savelearnerTpoReg", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.learner_tpo_reg.push(this.editedItem);
                this.showSnackbar(
                  "#4caf50",
                  " Tpo Registration Added Successfully..."
                ); // show snackbar on success
                this.onload();
              } else if (res.data.status.code == "NA") {
                this.showSnackbar(
                  "#b71c1c",
                  " Tpo Registration Already Present!!!"
                ); // show snackbar on error
              } else if (res.data.status.code == "DE") {
                this.showSnackbar(
                  "#b71c1c",
                  "You are not allowed to do multiple registrations in same Academic-Year !"
                );
              }
            })
            .catch((error) => {
              //window.console.log(error);
            });

          this.close();
        }
      }
    }, // end of save()

    deleteTPORegistration(item) {
      //console.log("a");

      const data = {
        deleteitem: item.id,
      };

      axios
        .post("/TPORegistration/deleteTPORegistration", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onload();
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
         // window.console.log(error);
        });
      this.close();
    },

    // deleteItem(item) {
    //     var r = confirm("Are you sure you want to delete this item?");
    //     if (r) {
    //         const tpoId = {
    //         id: item.id,
    //         };
    //         axios
    //         .post("/TPORegistration/deleteTPOReg", tpoId)
    //         .then(res => {
    //             if (res.data.msg == "200") {
    //             this.onload()
    //             this.showSnackbar("#4CAF50", "TPO Registration Deleted...");

    //             }
    //         })
    //         .catch(error => {
    //             this.showSnackbar("#b71c1c", "Something went wrong!!!");
    //         })
    //         .finally(() => {});
    //     } else {}
    //     },

    isValidated() {
      //  if (this.editedItem.ptype && this.editedItem.ay && this.editedItem.sem) {
      if (this.editedItem.ptype && this.editedItem.ay) {
        return true;
      } else {
        if (!this.editedItem.ptype) {
          this.errorMsg.ptype = true;
          this.showSnackbar("#b71c1c", " Select Placement Type... ");
        } else if (!this.editedItem.ay) {
          this.errorMsg.ay = true;
          this.showSnackbar("#b71c1c", " Select Academic year... ");
        }
        //    else if (!this.editedItem.sem) {
        //         this.errorMsg.sem = true;
        //          this.showSnackbar("#b71c1c", " Select Semester... ");
        //     }
        return false;
      }
    },
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.logi {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

table, td, th {
  border: 1px solid;
}

table {
  width: 900px;
  border-collapse: collapse;
}
</style>
